import { createHttpEndpoint } from '../../utils'

/**
 * Get customer social security number
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#tag/social-security-number/operation/getCustomerSocialSecurityNumber}
 */
export const getSsn = createHttpEndpoint({
  method: 'GET',
  operationId: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

/**
 * Update customer social security number
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#tag/social-security-number/operation/putCustomerSocialSecurityNumber}
 */
export const putSsn = createHttpEndpoint({
  method: 'PUT',
  operationId: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

/**
 * TODO Add link to API specs when available
 */
export const getIdentityDocuments = createHttpEndpoint({
  method: 'GET',
  operationId: 'getClientIdentityDocuments',
  path: '/bm/client/identity_documents',
})

/**
 * TODO Add link to API specs when available
 */
export const postIdentityDocuments = createHttpEndpoint({
  method: 'POST',
  operationId: 'setIdentityDocuments',
  path: '/bm/client/identity_documents',
  // TODO [PAYIN-2621] Add support for FormData
  // isFormData: true,
})

/**
 * Update customer social security number
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#tag/social-security-number/operation/deleteCustomerSocialSecurityNumber}
 */
export const deleteIdentityDocuments = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteIdentityDocuments',
  path: '/bm/client/identity_documents',
})

/**
 * TODO Add link to API specs when available
 */
interface ClientBankDetailsPayload {
  bankDetailsDocument: string
  isBankDetailsDocumentDeletable: boolean
}

export const getBankDetails = createHttpEndpoint<ClientBankDetailsPayload>({
  method: 'GET',
  operationId: 'getClientBankDetails',
  path: '/bm/client/bank_details',
})

/**
 * TODO Add link to API specs when available
 */

export const putBankDetails = createHttpEndpoint<ClientBankDetailsPayload>({
  method: 'PUT',
  operationId: 'setBankDetailsDocument',
  path: '/bm/client/bank_details',
  // TODO [PAYIN-2621] Add support for FormData
  // isFormData: true,
})

/**
 * TODO Add link to API specs when available
 */
export const deleteBankDetails = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteBankDetailsDocument',
  path: '/bm/client/bank_details',
})
